/* eslint-disable string-to-lingui/missing-lingui-transformation */
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditOutlineIcon from '@mui/icons-material/EditOutlined'
import { IconButton } from '@mui/material'
import { PatientAgeQualifierDTO_Output } from '@om1/falcon-api'
import { DateAwareFilterDTO_Output } from '@om1/falcon-api/codegen/client/models/DateAwareFilterDTO_Output'
import React, { FC } from 'react'
import { QualifierContent } from '../nodes/QualifierContent'
import { capitalizeFirstLetter } from './CountDistinctQualifier'
import { QualifierProps } from './Qualifier'

export const PatientAgeQualifier: FC<
    Omit<QualifierProps, 'qualifier'> & {
        patientAgeQualifierDTO: PatientAgeQualifierDTO_Output
    }
> = ({
    patientAgeQualifierDTO,
    parent,
    onBlockClick,
    disableClick = false,
    qualifierIndex = -1,
    setEditBlock,
    selectedBlock,
    editDisabled,
    setSelectedBlock
}) => {
    const parentName = capitalizeFirstLetter((parent as DateAwareFilterDTO_Output).table.replace('patient_', ''))

    const handleBlockClick = () => {
        if (onBlockClick && parent?.id) {
            onBlockClick(parent.id, qualifierIndex)
        }
    }

    const handleEditClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        if (setEditBlock) {
            setEditBlock({
                filter: parent,
                index: qualifierIndex
            })
        }
    }

    const determineLabel = (
        node: PatientAgeQualifierDTO_Output
    ): 'At most' | 'At least' | 'Exactly' | 'Less than' | 'More than' | 'At any time between' | undefined => {
        const { intervalStart, intervalEnd, intervalIsInclusive } = node
        if ((intervalStart === 0 || intervalStart === null) && intervalEnd !== null && intervalIsInclusive) return 'At most'
        if (intervalStart !== null && intervalEnd === null && intervalIsInclusive) return 'At least'
        if (intervalStart === intervalEnd && intervalIsInclusive) return 'Exactly'
        if (intervalStart !== null && intervalEnd !== null && intervalIsInclusive && intervalStart !== intervalEnd) return 'At any time between'
        if ((intervalStart === 0 || intervalStart === null) && intervalEnd !== null && !intervalIsInclusive) return 'Less than'
        if (intervalStart !== null && intervalEnd === null && !intervalIsInclusive) return 'More than'
        return undefined
    }

    const ageLabel = `Patient Age ${determineLabel(patientAgeQualifierDTO)?.toLowerCase()} ${
        patientAgeQualifierDTO.intervalStart || patientAgeQualifierDTO.intervalEnd
    } ${
        patientAgeQualifierDTO.intervalStart !== null &&
        patientAgeQualifierDTO.intervalEnd !== null &&
        patientAgeQualifierDTO.intervalIsInclusive &&
        patientAgeQualifierDTO.intervalStart !== patientAgeQualifierDTO.intervalEnd
            ? `and ${patientAgeQualifierDTO.intervalEnd}`
            : ''
    } ${patientAgeQualifierDTO.intervalUnit}s Old At Initial ${parentName}`

    return (
        <QualifierContent
            qualifier={patientAgeQualifierDTO}
            parent={parent}
            onBlockClick={handleBlockClick}
            disableClick={disableClick}
            qualifierIndex={qualifierIndex}
            setEditBlock={setEditBlock}
            selectedBlock={selectedBlock}
            setSelectedBlock={setSelectedBlock}
        >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ fontSize: '.875rem', lineHeight: '1rem' }}>{ageLabel}</div>
                    {!editDisabled && (
                        <div style={{ display: 'flex' }}>
                            <IconButton key='edit' color='primary' aria-label='Edit' sx={{ padding: 0.25 }} onClick={handleEditClick}>
                                <EditOutlineIcon fontSize='small' />
                            </IconButton>
                            <IconButton key='delete' color='primary' aria-label='Delete' sx={{ padding: 0.25 }} onClick={() => {}}>
                                <DeleteOutlineIcon fontSize='small' />
                            </IconButton>
                        </div>
                    )}
                </div>
            </div>
        </QualifierContent>
    )
}
