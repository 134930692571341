/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { t } from '@lingui/macro'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import { styled } from '@mui/system'
import { AssistantConversationPatchDTO } from '@om1/falcon-api/codegen/client/models/AssistantConversationPatchDTO'
import { ChatModel } from '@om1/falcon-api/codegen/client/models/ChatModel'
import { useConversation } from '@om1/falcon-api/codegen/hooks/useAssistantConversation'
import { useUpdateConversation } from '@om1/falcon-api/codegen/hooks/useAssistantConversations'
import { useUserPermissions } from '@om1/platform-authentication/auth0/hooks/useUserPermissions'
import EditableParagraph from '@om1/platform-components/EditableParagraph'
import { PlatformPermissions } from '@om1/platform-utils/enums'
import { ArrowLeft } from 'lucide-react'
import { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { OptionsMenu } from './OptionsMenu'

export const Header = (props: { conversationId: string }) => {
    const history = useHistory()
    const navigate = history.push
    const { permissions } = useUserPermissions()

    // Memoize the parameters to ensure stable query keys
    const conversationParams = useMemo(() => ({ id: props.conversationId, model: 'gpt-4o' as ChatModel }), [props.conversationId])

    const { assistantConversationDetail } = useConversation(conversationParams)
    const updateConversation = useUpdateConversation()

    const handleUpdateConversation = (newTitle: string) => {
        if (newTitle !== (assistantConversationDetail?.title || assistantConversationDetail?.id)) {
            const updatedConversation: AssistantConversationPatchDTO = {
                title: newTitle
            }

            updateConversation.mutate({ id: props.conversationId, requestBody: updatedConversation })
        }
    }

    const BetaLabel = styled('span')(({ theme }) => ({
        backgroundColor: '#012D72',
        color: '#fff',
        borderRadius: '4px',
        fontSize: '0.7rem',
        padding: '2px 8px',
        marginLeft: theme.spacing(1)
    }))

    return (
        <div style={{ backgroundColor: '#ffffff', borderBottom: '1px solid #e2e8f0' }}>
            <div
                style={{
                    margin: '0 auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    maxHeight: '75px'
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginLeft: '12.5px' }}>
                    <button
                        onClick={() => history.goBack()}
                        style={{ padding: '0.5rem', borderRadius: '50%', transition: 'background-color 0.2s', cursor: 'pointer' }}
                    >
                        <ArrowLeft style={{ width: '1.25rem', height: '1.25rem' }} />
                    </button>
                    <AutoAwesomeIcon sx={{ color: '#012D72' }} />
                    <BetaLabel>Beta</BetaLabel>
                    <div style={{ marginTop: '-15px' }}>
                        <EditableParagraph
                            tooltip={t`Click to name conversation`}
                            sx={{
                                alignSelf: 'flex-start',
                                marginTop: 0
                            }}
                            text={assistantConversationDetail?.title || assistantConversationDetail?.id}
                            editable={true}
                            onSave={handleUpdateConversation}
                        />
                    </div>
                    {permissions.includes(PlatformPermissions.ACCESS_ADVANCED_ASSISTANT) && assistantConversationDetail?.referenceCohortId && (
                        <p
                            style={{ fontSize: '1rem', color: '#718096', cursor: 'pointer', marginLeft: '35px', lineHeight: '0.2rem' }}
                            onClick={() => navigate(`/cohort-builder/custom/${assistantConversationDetail?.referenceCohortId}/edit`)}
                        >
                            Cohort: {assistantConversationDetail?.referenceCohortId}
                        </p>
                    )}
                </div>
                {assistantConversationDetail?.assistantCohortId && (
                    <OptionsMenu
                        conversationId={props.conversationId}
                        assistantCohortId={assistantConversationDetail?.assistantCohortId}
                        referenceCohortId={assistantConversationDetail?.referenceCohortId}
                    />
                )}
            </div>
        </div>
    )
}
