import { Trans } from '@lingui/macro'
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material'
import { BasicCardStat } from '@om1/platform-components/BasicCard'
import { FunctionComponent, ReactNode, useMemo } from 'react'
import { CohortSizeWithLoader } from '../../components/shared/CohortSizeWithLoader'
import { DatasetSize } from '../../components/shared/DatasetSize'
import { CohortListItem } from '../../state'

export interface CohortDashboardCohortCardDetailsProps {
    cohort: CohortListItem
    sizeLoading?: boolean
}

const dateFormatter = new Intl.DateTimeFormat(undefined, { dateStyle: 'short' })

const StatCard = ({ id, label, value }: { id: string; label: ReactNode; value: ReactNode }) => <BasicCardStat {...{ id, label, value }} />

/**
 * A card that summarizes a single cohort.
 */
export const CohortDashboardCohortCardDetails: FunctionComponent<CohortDashboardCohortCardDetailsProps> = ({ cohort, sizeLoading }) => {
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

    const boxStyles = {
        padding: '16px',
        width: '100%', // Ensures the Box takes the full width
        flexWrap: isSmallScreen ? 'wrap' : 'nowrap' // Wraps the content on small screens
    }

    const renderUpdatedDate = useMemo(() => {
        const renderDateGridField = (date: Date) => {
            return (
                <Grid item xs={2} sx={{ minWidth: 'fit-content' }}>
                    <StatCard id='cohort-grid-updated' label={<Trans>Updated</Trans>} value={dateFormatter.format(date)} />
                </Grid>
            )
        }

        if (cohort.isSystem && cohort.updatedDttm) {
            return renderDateGridField(new Date(cohort.updatedDttm))
        }

        if (!cohort.isSystem && cohort.queryUpdatedDttm) {
            return renderDateGridField(new Date(cohort.queryUpdatedDttm))
        }

        return null
    }, [cohort])

    return (
        <Box sx={boxStyles}>
            <Grid container spacing={0}>
                <Grid item xs={2} sx={{ minWidth: 'fit-content' }}>
                    <StatCard
                        id='cohort-grid-size'
                        label={<Trans>Cohort Size</Trans>}
                        value={
                            <CohortSizeWithLoader
                                {...cohort}
                                sizeLoading={sizeLoading}
                                datasetSize={cohort.analyticsDataset.size}
                                isStale={cohort.isStale}
                                isSystem={cohort.isSystem}
                                cohortSize={cohort.cohortSize}
                            />
                        }
                    />
                </Grid>
                {!cohort.isSystem && (
                    <Grid item xs={3} sx={{ overflow: 'hidden' }}>
                        <StatCard
                            id='cohort-grid-version'
                            label={<Trans>Dataset</Trans>}
                            value={<DatasetSize name={cohort.analyticsDataset.name} size={cohort.analyticsDataset.size} />}
                        />
                    </Grid>
                )}
                {!cohort.isSystem && cohort.createdDttm && (
                    <Grid item xs={2} sx={{ minWidth: 'fit-content' }}>
                        <StatCard
                            id='cohort-grid-creator'
                            label={<Trans>Created</Trans>}
                            value={dateFormatter.format(new Date(cohort.createdDttm))}
                        />
                    </Grid>
                )}
                <Grid item xs={2} sx={{ minWidth: 'fit-content' }}>
                    <StatCard id='cohort-grid-owner' label={<Trans>Owner</Trans>} value={cohort.createdByEmail || ''} />
                </Grid>
                {renderUpdatedDate}
                <Grid item xs={2} /> {/* If this empty grid item is for spacing, it's okay to leave it. */}
            </Grid>
        </Box>
    )
}
