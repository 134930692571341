import { Box } from '@mui/material'

export interface BetaTagProps {
    label?: string
    onClick?: () => void
}

export const BetaTag = ({ label = 'BETA', onClick }: BetaTagProps) => {
    const renderInnerLabel = () => {
        if (onClick) {
            return (
                <Box onClick={onClick} sx={{ cursor: 'default' }}>
                    {label}
                </Box>
            )
        }
        return label
    }

    return (
        <Box
            sx={{
                paddingLeft: '15px',
                display: 'flex',
                justifyContent: 'flex-end',
                paddingRight: '15px',
                backgroundColor: '#F10174',
                borderRadius: '10px',
                fontWeight: 'bold',
                color: 'white',
                marginBottom: '15px',
                fontSize: '12px',
                marginLeft: '5px',
                verticalAlign: 'middle'
            }}
        >
            {renderInnerLabel()}
        </Box>
    )
}
