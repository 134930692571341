import { ExplorerAnalyticsRefService, PaginatedDictDTO_str_Any_ } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import camelcaseKeys from 'camelcase-keys'
import { call, put, select } from 'redux-saga/effects'
import {
    cohortBlocksEditActions,
    DiagnosisIcd10Ref,
    LabRef,
    MedicationCuiRef,
    MedicationNdcRef,
    MedicationRef,
    ObservationRef,
    PhenotypeRef,
    ProductCustomCohortRef,
    RaceRef,
    SexRef,
    SpecialtyRef,
    StateRef
} from '../state'
import { analyticsRefActions } from '../state/refs/analytics-refs-actions'
import { AnalyticsDimensionToTableName } from './get-demographic-refs-saga'

export function createQueryRefsSaga() {
    return function* (action: ReturnType<typeof cohortBlocksEditActions.queryRefs>) {
        const { destination, dimension } = action.payload
        const { page, limit, query } = action.payload
        const state = yield select()
        const existingRefs = state?.cohort?.refs?.[dimension]?.[destination]?.data ?? []
        yield put(cohortBlocksEditActions.setQueryRefsLoading({ dimension, destination, loading: true }))
        try {
            const filters = Array.isArray(query) ? query : []
            const payload = filters.length > 0 ? { dimension, page, limit, filters } : { dimension, page, limit, query: query as string }

            const paginatedDictDTOAny: PaginatedDictDTO_str_Any_ = yield call(
                ExplorerAnalyticsRefService.indexExplorerAnalyticsRefRefTableNameGet as any,
                {
                    ...payload,
                    refTableName: AnalyticsDimensionToTableName[dimension]
                }
            )

            yield put(
                analyticsRefActions.setRefs({
                    dimension,
                    destination,
                    data: [...existingRefs, ...camelcaseKeys(paginatedDictDTOAny.data)] as
                        | RaceRef[]
                        | SexRef[]
                        | StateRef[]
                        | DiagnosisIcd10Ref[]
                        | MedicationRef[]
                        | MedicationCuiRef[]
                        | MedicationNdcRef[]
                        | LabRef[]
                        | SpecialtyRef[]
                        | PhenotypeRef[]
                        | ProductCustomCohortRef[]
                        | ObservationRef[],
                    paginationMeta: paginatedDictDTOAny.meta
                })
            )
        } catch (error: any) {
            console.log('error', error)
            yield handleApiError(error)
        } finally {
            yield put(cohortBlocksEditActions.setQueryRefsLoading({ dimension, destination, loading: false }))
        }
    }
}

export type QueryRefsSaga = ReturnType<typeof createQueryRefsSaga>
