import { useAuth0, User } from '@auth0/auth0-react'
import { jwtDecode } from 'jwt-decode'
import { useEffect, useState } from 'react'

type DecodedToken = {
    iss: string
    sub: string
    aud: string[]
    iat: number
    exp: number
    scope: string
    org_id: string
    org_name: string
    azp: string
    permissions: string[]
}

interface UserPermissions {
    permissions: string[]
    user?: User
    decodedToken?: DecodedToken
}

export const useUserPermissions = (): UserPermissions => {
    const { getAccessTokenSilently, user } = useAuth0()
    const [decodedToken, setDecodedToken] = useState<DecodedToken | undefined>(undefined)
    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                const token = await getAccessTokenSilently()
                const decodedToken = jwtDecode<DecodedToken>(String(token))
                setDecodedToken(decodedToken)
            } catch (error) {
                console.error('Failed to get or decode token:', error)
                setDecodedToken(undefined)
            }
        }

        fetchPermissions()
    }, [getAccessTokenSilently])

    return { permissions: decodedToken?.permissions || [], decodedToken, user }
}
