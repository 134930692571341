import { track, trackingEvent, TrackingPlanEvents } from '@om1/platform-tracking'
import { ActionsUnion, createAction } from '@om1/platform-utils'
import {
    CohortNodeSourceAddress,
    CohortNodeTargetAddress,
    CohortTreeActionType,
    CriteriaDialogClosedState,
    CriteriaFiltersDialogState,
    CriteriaOperation,
    CriteriaReference,
    CriteriaRelationDialogOpenState,
    CriteriaType,
    DataTypesDialogState,
    DateFieldOptions,
    DateRelationMetadata,
    FilterQualifier,
    FollowUpLengthEditDialogState,
    FollowUpRelationMetadata,
    LabNumericQualifierEditDialogState,
    ObservationScoreEditDialogState,
    OccurrenceEditDialogState,
    OperationNode,
    PatientAgeEditDialogState,
    QueryFilterBase,
    QueryFilterCondition,
    QueryFilters,
    QueryRefsActionPayload,
    RecencyEditDialogState,
    RefLabelsState,
    RelativeDateFilterDTO,
    ReportQueryFilters,
    SpecialtyEditDialogState,
    TreeEditMode
} from '../../../state'
import { AnalyticsRefDestination, AnalyticsRefDimension } from '../../refs'

export enum CohortBlocksEditActionTypes {
    RESET = '@@cohort/edit-blocks/reset',
    COHORT_DRAG_START = '@@cohort/edit-blocks/drag-start',
    COHORT_DRAG_END = '@@cohort/edit-blocks/drag-end',
    COHORT_SET_LAST_SAVED_BLOCKS = '@@cohort/edit-blocks/cohort-set-last-saved-blocks',
    OPERATION_DELETE = '@@cohort/edit-blocks/operation-delete',
    CRITERIA_INSERT = '@@cohort/edit-blocks/criteria-insert',
    CRITERIA_UPDATE = '@@cohort/edit-blocks/criteria-update',
    CRITERIA_COPY = '@@cohort/edit-blocks/criteria-copy',
    CRITERIA_MOVE = '@@cohort/edit-blocks/criteria-move',
    CRITERIA_DELETE = '@@cohort/edit-blocks/criteria-delete',
    CRITERIA_DIALOG_TRIGGER = '@@cohort/edit-blocks/criteria-dialog-trigger',
    CLEAN_TREE = '@@cohort/edit-blocks/clean-tree',
    TREE_EDIT_MODE_UPDATE = '@@cohort/edit-blocks/tree-edit-mode-update',
    OPERATION_INSERT = '@@cohort/edit-blocks/operation-insert',
    OPERATION_UPDATE = '@@cohort/edit-blocks/operation-update',
    EXCLUDED_UPDATE = '@@cohort/edit-blocks/set-excluded',
    CRITERIA_RELATION_DIALOG_TRIGGER = '@@cohort/edit-blocks/criteria-relation-dialog-trigger',
    CRITERIA_RELATION_UPDATE = '@@cohort/edit-blocks/criteria-relation-update',
    OCCURRENCE_EDIT_DIALOG_TRIGGER = '@@cohort/edit-blocks/occurrence-edit-dialog-trigger',
    RECENCY_EDIT_DIALOG_TRIGGER = '@@cohort/edit-blocks/recency-edit-dialog-trigger',
    DATA_TYPES_DIALOG_TRIGGER = '@@cohort/edit-blocks/data-types-dialog-trigger',
    PATIENT_AGE_EDIT_DIALOG_TRIGGER = '@@cohort/edit-blocks/patient-age-edit-dialog-trigger',
    SPECIALTY_EDIT_DIALOG_TRIGGER = '@@cohort/edit-blocks/specialty-edit-dialog-trigger',
    FOLLOW_UP_LENGTH_EDIT_DIALOG_TRIGGER = '@@cohort/edit-blocks/follow-up-length-dialog-trigger',
    OBSERVATION_SCORE_EDIT_DIALOG_TRIGGER = '@@cohort/edit-blocks/observation-score-edit-dialog-trigger',
    LAB_NUMERIC_TEXT_QUALIFIER_EDIT_DIALOG_TRIGGER = '@@cohort/edit-blocks/lab-numeric-qualifier-edit-dialog-trigger',
    REFS_QUERY = '@@cohort/edit-blocks/refs-query',
    REFS_QUERY_LOADING = '@@cohort/edit-blocks/refs-query-loading',
    REF_LABELS_LOADING_SET = '@@cohort/edit-blocks/cohort-ref-labels-loading-set',
    REF_LABELS_GET = '@@cohort/edit-blocks/cohort-refs-labels-get',
    REF_LABELS_MERGE = '@@cohort/edit-blocks/cohort-ref-labels-merge',
    OBSERVATION_PERIOD_UPDATE = '@@cohort/edit-blocks/observation-period-update'
}

export type CriteriaInsertPayload = {
    target: CohortNodeTargetAddress
    uuid: string
    criteriaType: CriteriaType
    filters?: (Exclude<QueryFilterCondition, RelativeDateFilterDTO> | QueryFilterBase)[]
    qualifiers?: FilterQualifier[]
    dateField?: DateFieldOptions
    startDateField?: DateFieldOptions
    endDateField?: DateFieldOptions
    dateRelation?: DateRelationMetadata
    insertFirst?: boolean
    followUpRelation?: FollowUpRelationMetadata
}

export type CriteriaUpdatePayload = {
    target: CohortNodeSourceAddress
    filters?: (Exclude<QueryFilterCondition, RelativeDateFilterDTO> | QueryFilterBase)[]
    qualifiers?: FilterQualifier[]
    dateField?: DateFieldOptions
}

export const cohortBlocksEditActions = {
    reset: () => createAction(CohortBlocksEditActionTypes.RESET),
    cohortDragStart: (payload: { allowRelate: boolean }) =>
        track(createAction(CohortBlocksEditActionTypes.COHORT_DRAG_START, payload), trackingEvent(TrackingPlanEvents.COHORT_DRAG_START, payload)),
    cohortDragEnd: () => track(createAction(CohortBlocksEditActionTypes.COHORT_DRAG_END), trackingEvent(TrackingPlanEvents.COHORT_DRAG_END)),
    cohortSetLastSavedBlocks: (payload: { blocks: OperationNode }) => createAction(CohortBlocksEditActionTypes.COHORT_SET_LAST_SAVED_BLOCKS, payload),
    operationDelete: (payload: { nodeId: string }) => createAction(CohortBlocksEditActionTypes.OPERATION_DELETE, payload),
    criteriaInsert: (payload: CriteriaInsertPayload, disableTracking: boolean = false) => {
        const result = payload?.filters?.reduce((acc, filter) => {
            if ('field' in filter && 'values' in filter) {
                acc[filter.field] = filter.values
            }
            return acc
        }, {})
        const { filters, ...rest } = payload
        return disableTracking
            ? createAction(CohortBlocksEditActionTypes.CRITERIA_INSERT, payload)
            : track(
                  createAction(CohortBlocksEditActionTypes.CRITERIA_INSERT, payload),
                  trackingEvent(TrackingPlanEvents.CRITERIA_INSERT, { ...rest, ...result })
              )
    },
    criteriaUpdate: (payload: CriteriaUpdatePayload, disableTracking: boolean = false) =>
        disableTracking
            ? createAction(CohortBlocksEditActionTypes.CRITERIA_UPDATE, payload)
            : track(createAction(CohortBlocksEditActionTypes.CRITERIA_UPDATE, payload), trackingEvent(TrackingPlanEvents.CRITERIA_UPDATE, payload)),
    criteriaCopy: (payload: { source: CohortNodeSourceAddress; target?: CohortNodeTargetAddress }, disableTracking: boolean = false) =>
        disableTracking
            ? createAction(CohortBlocksEditActionTypes.CRITERIA_COPY, payload)
            : track(createAction(CohortBlocksEditActionTypes.CRITERIA_COPY, payload), trackingEvent(TrackingPlanEvents.CRITERIA_COPY, payload)),
    criteriaMove: (payload: { source: CohortNodeSourceAddress; target: CohortNodeTargetAddress }, disableTracking: boolean = false) =>
        disableTracking
            ? createAction(CohortBlocksEditActionTypes.CRITERIA_MOVE, payload)
            : track(createAction(CohortBlocksEditActionTypes.CRITERIA_MOVE, payload), trackingEvent(TrackingPlanEvents.CRITERIA_MOVE, payload)),
    criteriaDelete: (payload: CohortNodeSourceAddress, disableTracking: boolean = false) =>
        disableTracking
            ? createAction(CohortBlocksEditActionTypes.CRITERIA_DELETE, payload)
            : track(createAction(CohortBlocksEditActionTypes.CRITERIA_DELETE, payload), trackingEvent(TrackingPlanEvents.CRITERIA_DELETE, payload)),
    criteriaDialogTrigger: (payload: CriteriaFiltersDialogState) => createAction(CohortBlocksEditActionTypes.CRITERIA_DIALOG_TRIGGER, payload),
    occurrenceEditDialogTrigger: (payload: OccurrenceEditDialogState) =>
        createAction(CohortBlocksEditActionTypes.OCCURRENCE_EDIT_DIALOG_TRIGGER, payload),
    recencyEditDialogTrigger: (payload: RecencyEditDialogState) => createAction(CohortBlocksEditActionTypes.RECENCY_EDIT_DIALOG_TRIGGER, payload),
    dataTypesDialogTrigger: (payload: DataTypesDialogState) => createAction(CohortBlocksEditActionTypes.DATA_TYPES_DIALOG_TRIGGER, payload),
    patientAgeEditDialogTrigger: (payload: PatientAgeEditDialogState) =>
        createAction(CohortBlocksEditActionTypes.PATIENT_AGE_EDIT_DIALOG_TRIGGER, payload),
    specialtyEditDialogTrigger: (payload: SpecialtyEditDialogState) =>
        createAction(CohortBlocksEditActionTypes.SPECIALTY_EDIT_DIALOG_TRIGGER, payload), // TODO
    followUpLengthEditDialogTrigger: (payload: FollowUpLengthEditDialogState) =>
        createAction(CohortBlocksEditActionTypes.FOLLOW_UP_LENGTH_EDIT_DIALOG_TRIGGER, payload),
    observationScoreEditDialogTrigger: (payload: ObservationScoreEditDialogState) =>
        createAction(CohortBlocksEditActionTypes.OBSERVATION_SCORE_EDIT_DIALOG_TRIGGER, payload),
    labNumericTextQualifierEditDialogTrigger: (payload: LabNumericQualifierEditDialogState) =>
        createAction(CohortBlocksEditActionTypes.LAB_NUMERIC_TEXT_QUALIFIER_EDIT_DIALOG_TRIGGER, payload),
    operationInsert: (
        payload: { uuid: string; target: CohortNodeTargetAddress; operation: CriteriaOperation; insertFirst?: boolean },
        disableTracking: boolean = false
    ) =>
        disableTracking
            ? createAction(CohortBlocksEditActionTypes.OPERATION_INSERT, payload)
            : track(createAction(CohortBlocksEditActionTypes.OPERATION_INSERT, payload), trackingEvent(TrackingPlanEvents.OPERATION_INSERT, payload)),
    operationUpdate: (
        payload: { target: CohortNodeTargetAddress; operation: Partial<Pick<OperationNode, 'name'>> },
        disableTracking: boolean = false
    ) =>
        disableTracking
            ? createAction(CohortBlocksEditActionTypes.OPERATION_UPDATE, payload)
            : track(createAction(CohortBlocksEditActionTypes.OPERATION_UPDATE, payload), trackingEvent(TrackingPlanEvents.OPERATION_UPDATE, payload)),
    excludedUpdate: (
        payload: { target: CohortNodeTargetAddress; operation: Partial<Pick<OperationNode, 'excluded'>> },
        disableTracking: boolean = false
    ) =>
        disableTracking
            ? createAction(CohortBlocksEditActionTypes.EXCLUDED_UPDATE, payload)
            : track(createAction(CohortBlocksEditActionTypes.EXCLUDED_UPDATE, payload), trackingEvent(TrackingPlanEvents.EXCLUDED_UPDATE, payload)),
    criteriaRelationDialogTrigger: (payload: CriteriaDialogClosedState | Omit<CriteriaRelationDialogOpenState, 'criteria'>) =>
        createAction(CohortBlocksEditActionTypes.CRITERIA_RELATION_DIALOG_TRIGGER, payload),
    criteriaRelationUpdate: (
        payload: { relationData: Omit<CriteriaReference, 'criteria'>; target: CohortNodeSourceAddress },
        trackEvent: boolean = false
    ) =>
        trackEvent
            ? track(
                  createAction(CohortBlocksEditActionTypes.CRITERIA_RELATION_UPDATE, payload),
                  trackingEvent(TrackingPlanEvents.CRITERIA_RELATION_UPDATE, payload)
              )
            : createAction(CohortBlocksEditActionTypes.CRITERIA_RELATION_UPDATE, payload),
    observationPeriodUpdate: (payload: {
        state: DataTypesDialogState
        target: CohortNodeTargetAddress | CohortNodeSourceAddress
        actionType: CohortTreeActionType.Insert | CohortTreeActionType.Update
    }) => createAction(CohortBlocksEditActionTypes.OBSERVATION_PERIOD_UPDATE, payload),
    cleanTree: () => createAction(CohortBlocksEditActionTypes.CLEAN_TREE),
    treeEditModeUpdate: (payload: { treeEditMode: TreeEditMode }) => createAction(CohortBlocksEditActionTypes.TREE_EDIT_MODE_UPDATE, payload),
    queryRefs: (payload: QueryRefsActionPayload) => createAction(CohortBlocksEditActionTypes.REFS_QUERY, payload),

    setQueryRefsLoading: (payload: { dimension: AnalyticsRefDimension; destination: AnalyticsRefDestination; loading: boolean }) =>
        createAction(CohortBlocksEditActionTypes.REFS_QUERY_LOADING, payload),

    getRefLabels: (payload: { query: QueryFilters | ReportQueryFilters | null | undefined }) =>
        createAction(CohortBlocksEditActionTypes.REF_LABELS_GET, payload),
    mergeRefLabels: (payload: RefLabelsState) => createAction(CohortBlocksEditActionTypes.REF_LABELS_MERGE, payload),
    setRefLabelsLoading: (payload: { loading: boolean }) => createAction(CohortBlocksEditActionTypes.REF_LABELS_LOADING_SET, payload)
}

export type CohortBlocksEditActions = ActionsUnion<typeof cohortBlocksEditActions>
