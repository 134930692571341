import { DataType } from '../../../components/edit/blocks/ObservationPeriodDialogComponent'
import { CriteriaOperation, CriteriaType, DateRelationMetadata, FilterQualifier, FollowUpRelationMetadata, QueryFilterBase } from '../../cohort-state'
import { AnalyticsRefDestination, AnalyticsRefDimension } from '../../refs'

/** Represents the three possible states of criteria dialog. */
export type CriteriaFiltersDialogState = CriteriaDialogClosedState | CriteriaFiltersDialogOpenState

/** Represents a null dialog, in a closed state.  */
export type CriteriaDialogClosedState = Record<string, never>

/** Represents a criteria dialog in a new state. For use when adding a new criteria to a cohort. */
export interface CriteriaFiltersDialogOpenState {
    type: CriteriaType
    action: CohortTreeInsertAction | CohortTreeUpdateAction
}

/** Represents the possible states of criteria relation dialog. */
export type CriteriaRelationDialogState = CriteriaDialogClosedState | CriteriaRelationDialogOpenState

export interface CriteriaRelationDialogOpenState {
    type: CriteriaRelationType
    target: CohortNodeSourceAddress
    criteria:
        | DiagnosisCriterion
        | ProcedureCriterion
        | LabTestCriterion
        | MedicationCriterion
        | ObservationCriterion
        | EhrNotesCriterion
        | ObservationPeriodCriterion
        | ExternalCohortCriterion
}

/** Represents the possible states of an occurrence edit dialog. */
export type OccurrenceEditDialogState = CriteriaDialogClosedState | OccurrenceEditDialogOpenState

export interface OccurrenceEditDialogOpenState {
    target?: CohortNodeSourceAddress
    relatedTarget?: CohortNodeSourceAddress
}

export type DataTypesDialogState = CriteriaDialogClosedState | DataTypesDialogOpenState

export interface DataTypesDialogOpenState {
    value: DataType
}

export type SpecialtyEditDialogState = CriteriaDialogClosedState | SpecialtyEditDialogOpenState

export interface SpecialtyEditDialogOpenState {
    target?: CohortNodeSourceAddress
}

/** Represents the possible states of a recency edit dialog. */
export type RecencyEditDialogState = CriteriaDialogClosedState | RecencyEditDialogOpenState

export interface RecencyEditDialogOpenState {
    target: CohortNodeSourceAddress
}

/** Represents the possible states of a observation period length edit dialog. */
export type FollowUpLengthEditDialogState = CriteriaDialogClosedState | FollowUpLengthEditDialogOpenState

export interface FollowUpLengthEditDialogOpenState {
    target: CohortNodeSourceAddress
}

export type PatientAgeEditDialogState = CriteriaDialogClosedState | PatientAgeEditDialogOpenState

export interface PatientAgeEditDialogOpenState {
    target: CohortNodeSourceAddress
}

export type ObservationScoreEditDialogState = CriteriaDialogClosedState | ObservationScoreEditDialogOpenState

export interface ObservationScoreEditDialogOpenState {
    target: CohortNodeSourceAddress
}

export type LabNumericQualifierEditDialogState = CriteriaDialogClosedState | LabNumericQualifierEditDialogOpenState

export interface LabNumericQualifierEditDialogOpenState {
    target: CohortNodeSourceAddress
    type: 'numeric' | 'text'
}

export type TreeEditMode = 'import' | 'edit'

/** Represents the state of dragging a new or existing criteria in the tree */
export interface CohortDraggingState {
    /**
     * Whether or not a user is dragging a criteria
     */
    active: boolean
    /**
     * Whether or not a dragged item can be related to other items.
     */
    allowRelate: boolean
    /**
     * Disables whether or not a dragged item can be dragged.
     */
    disable?: boolean
}

/** Represents key value pairs of human readable ref values to display in criteria blocks */
export interface RefLabelsState {
    [tableName: string]: {
        [fieldName: string]: {
            [fieldValue: string]: string | null
        }
    }
}

export interface QueryRefsActionPayload {
    dimension: AnalyticsRefDimension
    destination: AnalyticsRefDestination
    page?: number
    limit?: number
    query?: string | string[]
}

export interface CohortBlocksEditState {
    ui: {
        criteriaDialog: CriteriaFiltersDialogState
        criteriaRelationDialog: CriteriaRelationDialogState
        occurenceEditDialog: OccurrenceEditDialogState
        recencyEditDialog: RecencyEditDialogState
        dataTypeDialog: DataTypesDialogState
        patientAgeEditDialog: PatientAgeEditDialogState
        specialtyEditDialog: SpecialtyEditDialogState
        followUpLengthEditDialog: FollowUpLengthEditDialogState
        observationScoreEditDialog: ObservationScoreEditDialogState
        labNumericQualifierEditDialog: LabNumericQualifierEditDialogState
        dragging: CohortDraggingState
        lastSavedBlocks: OperationNode
        refLabels: RefLabelsState
        refLabelsLoading: boolean
        refsLoading: Record<AnalyticsRefDimension, Record<AnalyticsRefDestination, boolean>>
        treeEditMode: TreeEditMode
    }
    tree: OperationNode
}

const REF_LOADING: Record<AnalyticsRefDestination, boolean> = {
    [AnalyticsRefDestination.SEARCH]: false,
    [AnalyticsRefDestination.RESTORE]: false
}

export const initialCohortBlocksEditState: CohortBlocksEditState = {
    ui: {
        criteriaDialog: {},
        criteriaRelationDialog: {},
        occurenceEditDialog: {},
        recencyEditDialog: {},
        dataTypeDialog: {},
        patientAgeEditDialog: {},
        specialtyEditDialog: {},
        followUpLengthEditDialog: {},
        observationScoreEditDialog: {},
        labNumericQualifierEditDialog: {},
        lastSavedBlocks: { id: '', operation: CriteriaOperation.EXCEPT, children: [] },
        refsLoading: {
            [AnalyticsRefDimension.DIAGNOSIS_ICD10]: REF_LOADING,
            [AnalyticsRefDimension.DIAGNOSIS_ICD10_GEM_MAPPED]: REF_LOADING,
            [AnalyticsRefDimension.DIAGNOSIS_CUI]: REF_LOADING,
            [AnalyticsRefDimension.RACE]: REF_LOADING,
            [AnalyticsRefDimension.SEX]: REF_LOADING,
            [AnalyticsRefDimension.STATE]: REF_LOADING,
            [AnalyticsRefDimension.LAB]: REF_LOADING,
            [AnalyticsRefDimension.MEDICATION]: REF_LOADING,
            [AnalyticsRefDimension.MEDICATION_CUI]: REF_LOADING,
            [AnalyticsRefDimension.MEDICATION_NDC]: REF_LOADING,
            [AnalyticsRefDimension.OBSERVATION]: REF_LOADING,
            [AnalyticsRefDimension.SPECIALTY]: REF_LOADING,
            [AnalyticsRefDimension.PROCEDURE]: REF_LOADING,
            [AnalyticsRefDimension.PHENOTYPE]: REF_LOADING,
            [AnalyticsRefDimension.PRODUCT_CUSTOM_COHORT]: REF_LOADING
        },
        dragging: {
            active: false,
            allowRelate: false,
            disable: false
        },
        refLabels: {},
        refLabelsLoading: false,
        treeEditMode: 'edit'
    },
    tree: {
        id: '',
        operation: CriteriaOperation.EXCEPT,
        children: []
    }
}

/*
 * Actions interface and enum definitions
 */

export enum CohortTreeActionType {
    Insert = 'INSERT',
    Update = 'UPDATE',
    Move = 'MOVE'
}

type CohortTreeAction = CohortTreeMoveAction | CohortTreeInsertAction | CohortTreeUpdateAction

export interface CohortNodeSourceAddress {
    nodeId: string
    relateType?: CriteriaRelationType
}

export interface CohortNodeTargetAddress {
    nodeId?: string
    relate?: boolean
    relateType?: CriteriaRelationType
}

export interface CohortTreeInsertAction {
    uuid: string
    type: CohortTreeActionType.Insert
    target: CohortNodeTargetAddress
}

export interface CohortTreeUpdateAction {
    type: CohortTreeActionType.Update
    target: CohortNodeSourceAddress
    filters: QueryFilterBase[]
}

export interface CohortTreeMoveAction {
    type: CohortTreeActionType.Move
    source: CohortNodeSourceAddress
    target: CohortNodeTargetAddress
}

export enum CriteriaRelationType {
    Date = 'DATE',
    FollowUp = 'FOLLOW_UP'
}

/*
 * UI interface definitions
 */

export interface DiagnosisCriterion extends CriteriaBase {
    type: CriteriaType.Diagnosis
}

export interface PatientAttributeCriterion extends CriteriaBase {
    type: CriteriaType.PatientAttributes
}

export interface ProcedureCriterion extends CriteriaBase {
    type: CriteriaType.Procedure
}

export interface LabTestCriterion extends CriteriaBase {
    type: CriteriaType.LabTest
}

export interface MedicationCriterion extends CriteriaBase {
    type: CriteriaType.Medication
}

export interface ObservationCriterion extends CriteriaBase {
    type: CriteriaType.Observation
}

export interface ObservationPeriodCriterion extends CriteriaBase {
    type: CriteriaType.ObservationPeriod
    startDateField?: string
    endDateField?: string
}

export interface DemographicsCriterion extends CriteriaBase {
    type: CriteriaType.Demographics
}

export interface EhrNotesCriterion extends CriteriaBase {
    type: CriteriaType.EhrNotes
}

export interface ExternalCohortCriterion extends CriteriaBase {
    type: CriteriaType.ExternalCohort
}

export type CohortNode = OperationNode | CriterionNode

export interface OperationNode {
    id: string
    operation: CriteriaOperation
    name?: string
    children: CohortNode[]
    excluded?: boolean
}

export type CriterionNode =
    | DiagnosisCriterion
    | PatientAttributeCriterion
    | ProcedureCriterion
    | LabTestCriterion
    | MedicationCriterion
    | ObservationCriterion
    | ObservationPeriodCriterion
    | DemographicsCriterion
    | EhrNotesCriterion
    | ExternalCohortCriterion

export interface CriteriaBase {
    id: string
    type: CriteriaType
    filters: QueryFilterBase[]
    qualifiers: FilterQualifier[]
    dateField: DateFieldOptions
    reference?: CriteriaReference
}

export interface CriteriaReference {
    criteria: Omit<CriterionNode, 'reference'>
    dateRelation?: DateRelationMetadata
    followUpRelation?: FollowUpRelationMetadata
}

export type DateFieldOptions = 'first' | 'last' | 'any' | 'start' | 'end'

/* Type guards */

export function isCriterionNode(node: CohortNode): node is CriterionNode {
    return (node as CriterionNode).type !== undefined
}

export function isOperationNode(node: CohortNode): node is OperationNode {
    return (node as OperationNode).operation !== undefined
}

export function isAndOperationNode(node: CohortNode): node is OperationNode {
    return isOperationNode(node) && node.operation === CriteriaOperation.AND
}

export function isOrOperationNode(node: CohortNode): node is OperationNode {
    return isOperationNode(node) && node.operation === CriteriaOperation.OR
}

export function isInsertAction(action: CohortTreeAction): action is CohortTreeInsertAction {
    return action.type === CohortTreeActionType.Insert
}

export function isUpdateAction(action: CohortTreeAction): action is CohortTreeUpdateAction {
    return action.type === CohortTreeActionType.Update
}
