/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { CircularProgress } from '@mui/material'
import { withConfig } from '@om1/platform-utils'
import * as React from 'react'
import { PropsWithChildren, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Auth0ConfigInterface, auth0Config } from './auth0-config'

interface Auth0PlatformProviderComponentProps {
    children: JSX.Element
    config: Auth0ConfigInterface
}

export const Auth0PlatformProviderComponent: React.FunctionComponent<Auth0PlatformProviderComponentProps> = ({
    children,
    config
}: {
    children: JSX.Element
    config: Auth0ConfigInterface
}) => {
    const history = useHistory()

    const onRedirectCallback = (appState: any) => {
        history.push(appState?.returnTo || window.location.pathname)
    }

    return (
        <Auth0Provider
            domain={config.auth0Domain}
            clientId={config.auth0ClientId}
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: `${config.auth0AudienceDomain}/explorer`,
                scope: config.auth0PlatformScopes,
                prompt: 'login'
            }}
            onRedirectCallback={onRedirectCallback}
        >
            <AuthChecker>{children}</AuthChecker>
        </Auth0Provider>
    )
}

const AuthChecker: React.FC<PropsWithChildren> = ({ children }) => {
    const { isAuthenticated, isLoading, loginWithRedirect, error } = useAuth0()

    useEffect(() => {
        console.log('AuthChecker: Auth0 state - isLoading:', isLoading, 'isAuthenticated:', isAuthenticated)
        if (error) {
            console.error('Auth0 error:', error)
        }
        if (!isLoading && !isAuthenticated) {
            console.log('User is not authenticated, redirecting to login...')
            loginWithRedirect()
        }
    }, [isLoading, isAuthenticated, loginWithRedirect, error])

    console.log('Rendering AuthChecker - isLoading:', isLoading, 'isAuthenticated:', isAuthenticated)

    if (isLoading) {
        console.log('Rendering AuthChecker CircularProgress because isLoading is true')
        return (
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#f7fafc' // equivalent to bg-gray-100
                }}
            >
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress />
                </div>
            </div>
        )
    }

    console.log('Rendering children because isLoading is false and isAuthenticated is:', isAuthenticated)
    return <>{children}</>
}

export const useAuth = () => {
    const { isAuthenticated, isLoading, error, user, getAccessTokenSilently, logout } = useAuth0()

    return { isAuthenticated, isLoading, error, user, getAccessTokenSilently, logout }
}

export const Auth0PlatformProvider = withConfig<Auth0PlatformProviderComponentProps>(Auth0PlatformProviderComponent, auth0Config)
