/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { CircularProgress } from '@mui/material'
import { AssistantAIMessage_Output, AssistantHumanMessage, AssistantToolMessage } from '@om1/falcon-api'
import { OpenAPI } from '@om1/falcon-api/codegen/client/core/OpenAPI'
import { ChatModel } from '@om1/falcon-api/codegen/client/models/ChatModel'
import { useConversation } from '@om1/falcon-api/codegen/hooks/useAssistantConversation'
import { falconApiConfig } from '@om1/falcon-api/falcon-api-config'
import { Bot, SquareDashed, SquareDashedMousePointer, User, Wrench } from 'lucide-react'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { NodeTypes } from '../components/query/QueryBlock'
import { SelectedBlockProps } from './ChatView'
import { MessageForm } from './MessageForm'
import { RenderedMessage } from './RenderedMessage'
import { removeSelectedBlockIdMessage, selectedFilterKey } from './SystemMessages'

const MessageList = memo(
    ({
        messages,
        selectedBlock
    }: {
        messages: (AssistantAIMessage_Output | AssistantHumanMessage | AssistantToolMessage)[]
        selectedBlock: NodeTypes | undefined
    }) => {
        const toolCallsAreComplete = useMemo(() => {
            const toolCallStatuses = new Map<string, { complete: boolean; status?: string; error?: string }>()

            // First pass: collect all tool calls
            messages.forEach((message) => {
                if (message.additional_kwargs?.tool_calls) {
                    message.additional_kwargs.tool_calls.forEach((toolCall) => {
                        if (!toolCallStatuses.has(toolCall.id)) {
                            toolCallStatuses.set(toolCall.id, { complete: false })
                        }
                    })
                } else if (message.type === 'tool' && (message as AssistantToolMessage).tool_call_id) {
                    toolCallStatuses.set((message as AssistantToolMessage).tool_call_id, {
                        complete: true,
                        status: (message as AssistantToolMessage).status,
                        error: (message as AssistantToolMessage).content
                    })
                }
            })
            return toolCallStatuses
        }, [messages])

        return (
            <>
                {messages.map((msg, index) => {
                    const type =
                        msg['type'] === 'ai'
                            ? msg['additional_kwargs']?.tool_calls && msg['additional_kwargs'].tool_calls.length > 0
                                ? 'tool'
                                : 'ai'
                            : msg['type']
                    if (type === 'tool' && msg['tool_call_id'] !== undefined) {
                        return null
                    }
                    return (
                        <div
                            key={index}
                            style={{
                                display: 'flex',
                                alignItems: type === 'tool' ? 'center' : 'flex-start',
                                flexDirection: type !== 'human' && type !== 'system' ? 'row' : 'row-reverse',
                                gap: '1rem'
                            }}
                        >
                            <div
                                style={{
                                    flexShrink: 0,
                                    width: '2rem',
                                    height: '2rem',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: type === 'human' ? '#ebf8ff' : '#F5F5F5'
                                }}
                            >
                                {type === 'human' ? (
                                    <User
                                        style={{
                                            width: '1.75rem',
                                            height: '1.75rem',
                                            color: '#3182CE',
                                            fontFamily: ['Metropolis', 'sans-serif'].join(','),
                                            backgroundColor: 'white',
                                            borderRadius: '100px'
                                        }}
                                    />
                                ) : type === 'tool' ? (
                                    <Wrench
                                        style={{
                                            width: '1.75rem',
                                            height: '1.75rem',
                                            color: '#718096',
                                            fontFamily: ['Metropolis', 'sans-serif'].join(','),
                                            backgroundColor: 'transparent',
                                            borderRadius: '100px'
                                        }}
                                    />
                                ) : type === 'system' ? (
                                    msg.content.includes(selectedFilterKey) ? (
                                        <SquareDashedMousePointer
                                            style={{
                                                width: '1.75rem',
                                                height: '1.75rem',
                                                color: '#0000FF',
                                                fontFamily: ['Metropolis', 'sans-serif'].join(','),
                                                backgroundColor: '#FFFFFF',
                                                borderRadius: '100px'
                                            }}
                                        />
                                    ) : (
                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                            <SquareDashed
                                                style={{
                                                    width: '1.75rem',
                                                    height: '1.75rem',
                                                    color: '#FF1512',
                                                    fontFamily: ['Metropolis', 'sans-serif'].join(','),
                                                    backgroundColor: '#FFFFFF',
                                                    borderRadius: '100px'
                                                }}
                                            />
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    fontSize: '0.5rem',
                                                    fontWeight: 'bold',
                                                    pointerEvents: 'none'
                                                }}
                                            >
                                                ❌
                                            </span>
                                        </div>
                                    )
                                ) : (
                                    <Bot
                                        style={{
                                            width: '1.75rem',
                                            height: '1.75rem',
                                            color: '#718096',
                                            fontFamily: ['Metropolis', 'sans-serif'].join(','),
                                            backgroundColor: '#FFFFFF',
                                            borderRadius: '100px'
                                        }}
                                    />
                                )}
                            </div>
                            {type === 'human' && (
                                <div
                                    style={{
                                        flexGrow: 1,
                                        padding: '1rem',
                                        borderRadius: '0.5rem',
                                        textAlign: 'right',
                                        backgroundColor: '#FFFFFF',
                                        border: '1px solid #3182CE'
                                    }}
                                >
                                    <p style={{ color: '#2d3748', whiteSpace: 'pre-wrap' }}>
                                        {RenderedMessage({ message: msg, toolCallsAreComplete, selectedBlock })}
                                    </p>
                                </div>
                            )}
                            {type === 'ai' && (
                                <div
                                    style={{
                                        flexGrow: 1,
                                        padding: '1rem',
                                        borderRadius: '0.5rem',
                                        textAlign: 'left',
                                        backgroundColor: '#FFFFFF',
                                        border: '1px solid #718096'
                                    }}
                                >
                                    <p style={{ color: '#2d3748', whiteSpace: 'pre-wrap' }}>
                                        {RenderedMessage({ message: msg, toolCallsAreComplete, selectedBlock })}
                                    </p>
                                </div>
                            )}
                            {type === 'tool' && (
                                <div
                                    style={{
                                        flexGrow: 1,
                                        borderRadius: '0.5rem',
                                        textAlign: 'left',
                                        backgroundColor: '#transparent'
                                    }}
                                >
                                    <p style={{ color: '#2d3748', whiteSpace: 'pre-wrap' }}>
                                        {RenderedMessage({ message: msg, toolCallsAreComplete, selectedBlock })}
                                    </p>
                                </div>
                            )}
                            {type === 'system' && (
                                <div
                                    style={{
                                        flexGrow: 1,
                                        borderRadius: '0.5rem',
                                        textAlign: 'right',
                                        backgroundColor: '#transparent'
                                    }}
                                >
                                    <p style={{ color: '#2d3748', whiteSpace: 'pre-wrap' }}>
                                        {RenderedMessage({ message: msg, toolCallsAreComplete, selectedBlock })}
                                    </p>
                                </div>
                            )}
                        </div>
                    )
                })}
            </>
        )
    }
)

MessageList.displayName = 'MessageList'

export const AssistantConversation = (
    props: {
        conversationId: string
        onUpdateCohort?: () => void
    } & SelectedBlockProps
) => {
    const [model, setModel] = useState<ChatModel>('gpt-4o')
    const [message, setMessage] = useState('')
    const conversationParams = useMemo(() => ({ id: props.conversationId, model: 'gpt-4o' as ChatModel }), [props.conversationId])

    const { assistantConversationDetail, refetchAssistantConversationDetail } = useConversation(conversationParams)
    const [messages, setMessages] = useState<Array<AssistantAIMessage_Output | AssistantHumanMessage | AssistantToolMessage>>(
        assistantConversationDetail?.messages || []
    )
    const messagesEndRef = useRef<HTMLDivElement | null>(null)
    const [assistantIsResponding, setAssistantIsResponding] = useState<boolean>(false)
    const websocketUrl = `${falconApiConfig.falconApiUrl?.replace(new RegExp(/^http/), 'ws')}/explorer/assistant-conversation/${
        props.conversationId
    }?model=${model}`
    const wsRef = useRef<WebSocket | null>(null)

    useEffect(() => {
        const findCurrentFilterSelection = () => {
            for (let i = messages.length - 1; i >= 0; i--) {
                const message = messages[i]
                if (message.type === 'system') {
                    const match = message.content.includes(selectedFilterKey)
                    if (match) {
                        const filterId = message.content.replace(selectedFilterKey, '').trim()
                        return { id: Number(filterId), name: filterId } // Return the filter ID
                    } else if (message.content.includes(removeSelectedBlockIdMessage().content)) {
                        return undefined // Return undefined if filter is cleared
                    }
                }
            }
            return undefined // Return undefined if no system messages found
        }

        const currentFilterSelection = findCurrentFilterSelection()
        props.setSelectedBlock && props.setSelectedBlock({ ...currentFilterSelection })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Create stable references for both callbacks
    const refetchRef = useRef(refetchAssistantConversationDetail)
    const updateCohortRef = useRef(props.onUpdateCohort)

    // Keep refs updated
    useEffect(() => {
        refetchRef.current = refetchAssistantConversationDetail
    }, [refetchAssistantConversationDetail])

    useEffect(() => {
        updateCohortRef.current = props.onUpdateCohort
    }, [props.onUpdateCohort])

    const handleWebSocketMessage = useCallback((event: MessageEvent) => {
        const newMessage = JSON.parse(event.data)
        if (newMessage?.type === 'ai') {
            if (!(newMessage?.tool_calls?.length > 0)) {
                setAssistantIsResponding(false)
            }
        } else if (newMessage?.type === 'tool') {
            const status = newMessage['status']
            const name = newMessage['name']
            if (status) {
                switch (name) {
                    case 'update_conversation_title':
                        refetchRef.current()
                        break
                    case 'update_cohort':
                        updateCohortRef.current?.()
                        break
                    default:
                        break
                }
            }
        }
        setMessages((prevMessages) => [...prevMessages, newMessage])
    }, []) // Empty dependency array

    useEffect(() => {
        const ws = new WebSocket(websocketUrl)
        wsRef.current = ws

        ws.onopen = () => {
            ws.send(JSON.stringify({ token: String(OpenAPI.TOKEN) }))
        }

        ws.onmessage = handleWebSocketMessage

        ws.onerror = (error) => {
            console.error('WebSocket error:', error)
        }

        ws.onclose = () => {
            console.log('Assistant Conversation websocket connection closed')
        }

        return () => {
            ws.close()
        }
    }, [websocketUrl, handleWebSocketMessage])

    const sendMessage = (content: string) => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            wsRef.current.send(JSON.stringify({ type: 'human', content }))
            setMessages((prevMessages) => [...prevMessages, { type: 'human', content }])
            setAssistantIsResponding(true)
        }
    }

    const sendSystemMessage = (content: string) => {
        if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
            wsRef.current.send(JSON.stringify({ type: 'system', content }))
            setMessages((prevMessages) => [...prevMessages, { type: 'system', content }])
        }
    }

    const hasMounted = useRef(false)

    useEffect(() => {
        if (hasMounted.current) {
            if (props.selectedBlock) {
                sendSystemMessage(`Selected filter with ID: ${props.selectedBlock.id}`)
            } else {
                sendSystemMessage('Removed filter selection')
            }
        } else {
            hasMounted.current = true
        }
    }, [props.selectedBlock])

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [messages])

    return (
        <div style={{ minHeight: '80dvh', height: '80dvh', backgroundColor: '#F5F5F5', display: 'flex', flexDirection: 'column' }}>
            {/* Messages */}
            <div style={{ flex: 1, overflowY: 'auto', padding: '1rem', scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
                <style>
                    {`
                /* Hide scrollbar for Chrome, Safari and Opera */
                .hide-scrollbar::-webkit-scrollbar {
                    display: none;
                }

                /* Hide scrollbar for IE, Edge and Firefox */
                .hide-scrollbar {
                    -ms-overflow-style: none;  /* IE and Edge */
                    scrollbar-width: none;  /* Firefox */
                }
            `}
                </style>
                <div className='hide-scrollbar' style={{ margin: '0 auto', display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>
                    <MessageList messages={messages} selectedBlock={props.selectedBlock} />
                    <div ref={messagesEndRef} />
                    {assistantIsResponding && (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', color: '#718096', fontStyle: 'italic' }}>
                            <CircularProgress color='inherit' size='1em' />
                            <span>Assistant is responding...</span>
                        </div>
                    )}
                </div>
            </div>

            {/* Input */}
            <div style={{ backgroundColor: '#ffffff', borderTop: '1px solid #e2e8f0', padding: '0rem .5rem 0rem .5rem' }}>
                <div style={{ margin: '0 auto' }}>
                    <MessageForm
                        sendMessage={sendMessage}
                        model={model}
                        setModel={setModel}
                        message={message}
                        setMessage={setMessage}
                        selectedBlock={props.selectedBlock}
                        setSelectedBlock={props.setSelectedBlock}
                        messageCount={messages.length}
                    />
                </div>
            </div>
        </div>
    )
}
