/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { Button } from '@mui/material'
import { DateRangeOperator } from '@om1/falcon-api/codegen/client/models/DateRangeOperator'
import { RelativeDateFilterDTO_Output } from '@om1/falcon-api/codegen/client/models/RelativeDateFilterDTO_Output'
import { FC } from 'react'
import { SelectedBlockProps } from '../../../views/ChatView'
import { FilterContent } from '../nodes/FilterContent'
import { EditQueryBlockProps } from '../Query'
import { BlockClickProps, QueryBlock, QueryBlockProps } from '../QueryBlock'

type LabelType = 'At most' | 'At least' | 'Exactly' | 'Less than' | 'More than' | 'At any time between'

export const determineLabel = (node: RelativeDateFilterDTO_Output): LabelType | undefined => {
    const { intervalStartFromReferenceDate, intervalEndFromReferenceDate, intervalIsInclusive, dateRangeOperator } = node
    if (dateRangeOperator === 'Between') return 'At any time between'
    if (intervalStartFromReferenceDate === 0 && intervalEndFromReferenceDate !== null && intervalIsInclusive) return 'At most'
    if (intervalStartFromReferenceDate !== null && intervalEndFromReferenceDate === null && intervalIsInclusive) return 'At least'
    if (intervalStartFromReferenceDate === intervalEndFromReferenceDate && intervalIsInclusive) return 'Exactly'
    if (intervalStartFromReferenceDate === 0 && intervalEndFromReferenceDate !== null && !intervalIsInclusive) return 'Less than'
    if (
        (intervalStartFromReferenceDate === null && intervalEndFromReferenceDate !== null) ||
        (intervalStartFromReferenceDate !== null && intervalEndFromReferenceDate === null) ||
        (intervalStartFromReferenceDate === null && intervalEndFromReferenceDate === null)
    )
        return 'More than'
    return undefined
}

export const getNodeFromLabel = (label: LabelType, amount: number): Partial<RelativeDateFilterDTO_Output> => {
    const baseNode = {
        intervalStartFromReferenceDate: amount,
        intervalEndFromReferenceDate: null,
        intervalIsInclusive: true,
        dateRangeOperator: 'After' as DateRangeOperator
    }
    switch (label) {
        case 'At most':
            return { ...baseNode, intervalStartFromReferenceDate: amount, intervalEndFromReferenceDate: amount, dateRangeOperator: 'Before' }
        case 'At least':
            return baseNode
        case 'Exactly':
            return { ...baseNode, intervalEndFromReferenceDate: amount }
        case 'Less than':
            return {
                ...baseNode,
                intervalStartFromReferenceDate: 0,
                intervalEndFromReferenceDate: amount,
                intervalIsInclusive: false,
                dateRangeOperator: 'Before'
            }
        case 'More than':
            return { ...baseNode, intervalIsInclusive: false }
        case 'At any time between':
            return { dateRangeOperator: 'Between' as DateRangeOperator }
        default:
            return {}
    }
}

const getRelationshipString = (node: RelativeDateFilterDTO_Output): string => {
    const label = determineLabel(node)
    const { intervalStartFromReferenceDate, intervalEndFromReferenceDate, intervalUnitFromReferenceDate, dateRangeOperator } = node
    const interval =
        intervalStartFromReferenceDate === 0 && intervalEndFromReferenceDate !== null
            ? intervalEndFromReferenceDate
            : intervalStartFromReferenceDate !== null
            ? intervalStartFromReferenceDate
            : intervalEndFromReferenceDate !== null
            ? intervalEndFromReferenceDate
            : null
    const unit = intervalUnitFromReferenceDate ? `${intervalUnitFromReferenceDate}${interval && interval === 1 ? '' : 's'}` : ''
    const operator = dateRangeOperator === 'BeforeOrAfter' ? 'before or after' : dateRangeOperator.toLowerCase()

    return `${label} ${interval} ${unit} ${operator}`.trim()
}

export type RelativeDateFilterProps = {
    relativeDateFilterDTO: RelativeDateFilterDTO_Output
} & Omit<SelectedBlockProps, 'setSelectedBlockId'> &
    BlockClickProps &
    EditQueryBlockProps &
    Omit<QueryBlockProps, 'block'>

export const RelativeDateFilter: FC<RelativeDateFilterProps> = (props) => {
    const { relativeDateFilterDTO, depth } = props
    const relationshipString = getRelationshipString(relativeDateFilterDTO)

    return (
        <FilterContent {...props} filterDTO={relativeDateFilterDTO}>
            <div
                style={{
                    backgroundColor: '#F5F5F5',
                    padding: '1rem',
                    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
                }}
            >
                <div style={{ width: '100%' }}>
                    <QueryBlock
                        {...props}
                        block={{
                            ...relativeDateFilterDTO.subjectFilter,
                            disabled: relativeDateFilterDTO.disabled || relativeDateFilterDTO.subjectFilter.disabled
                        }}
                        depth={depth + 1}
                        isSubject
                    />
                </div>
                <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div
                        style={{
                            position: 'absolute',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            height: '80px',
                            width: '1px',
                            backgroundColor: '#32578E',
                            zIndex: 0,
                            marginTop: '0px'
                        }}
                    />
                    <Button
                        component='span'
                        disabled={Boolean(props.editDisabled)}
                        onClick={(event) => {
                            event.stopPropagation()
                            event.preventDefault()
                        }}
                        style={{
                            position: 'sticky',
                            zIndex: 10,
                            padding: '0.5rem 1.5rem',
                            fontSize: '1rem',
                            border: '1px solid',
                            marginTop: '0.5rem',
                            marginBottom: '0.5rem',
                            backgroundColor: '#002D72',
                            borderColor: '#F5F5F5',
                            color: 'white',
                            borderRadius: '4px',
                            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)'
                        }}
                    >
                        {relationshipString}
                    </Button>
                </div>
                <div style={{ width: '100%' }}>
                    <QueryBlock
                        {...props}
                        block={{
                            ...relativeDateFilterDTO.referenceFilter,
                            disabled: relativeDateFilterDTO.disabled || relativeDateFilterDTO.referenceFilter.disabled
                        }}
                        depth={depth + 1}
                        isReference
                    />
                </div>
            </div>
        </FilterContent>
    )
}
