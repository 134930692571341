/* eslint-disable string-to-lingui/missing-lingui-transformation */
import Tooltip from '@mui/material/Tooltip'
import { FC } from 'react'

interface ListWithMoreProps {
    items: string[]
    maxItems?: number
    itemCount: number
}

export const ListWithMore: FC<ListWithMoreProps> = ({ items, maxItems = 2, itemCount }) => (
    <div style={{ marginTop: '0.25rem', paddingBottom: '0.25rem' }}>
        {items.slice(0, maxItems).map((item, index) => (
            <div key={index} style={{ marginBottom: '0.25rem' }}>
                {item ? item.trim() : ''}
            </div>
        ))}
        {itemCount > maxItems && (
            <Tooltip title={items.join('\n')} placement='bottom-start'>
                <div style={{ fontStyle: 'italic', cursor: 'help' }}>... and {itemCount - maxItems} more</div>
            </Tooltip>
        )}
    </div>
)
