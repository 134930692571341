/* eslint-disable string-to-lingui/missing-lingui-transformation */
import { CircularProgress } from '@mui/material'
import { useCohort } from '@om1/falcon-api/codegen/hooks/useCohort'
import { useUserPermissions } from '@om1/platform-authentication/auth0/hooks/useUserPermissions'
import { PlatformPermissions } from '@om1/platform-utils'
import { useCallback, useEffect, useState } from 'react'
import ReactJson from 'react-json-view'
import { EditBlockDialog } from '../components/query/dialogs/EditBlockDialog'
import { Query } from '../components/query/Query'
import { NodeTypes } from '../components/query/QueryBlock'
import { SelectedBlockProps } from './ChatView'

export type AssistantQueryProps = {
    id: string
    updateCohortCount?: number
} & SelectedBlockProps

export const AssistantQuery = (props: AssistantQueryProps) => {
    const { updateCohortCount = 0 } = props
    const [editBlock, setEditBlock] = useState<{ filter: NodeTypes; index: number | undefined } | null>(null)
    const { cohort, isRefreshing, refetchCohort, findBlock } = useCohort({ cohortId: props.id as string })
    const [showQueryComponent, setShowQueryComponent] = useState(true)
    const { permissions } = useUserPermissions()

    useEffect(() => {
        if (updateCohortCount > 0) {
            refetchCohort()
        }
    }, [refetchCohort, updateCohortCount])

    const toggleQueryView = () => {
        if (cohort?.query) {
            setShowQueryComponent((prev) => !prev)
        }
    }

    const buttonStyles = {
        padding: '0.5rem 1rem',
        backgroundColor: cohort?.query ? '#3182ce' : 'gray',
        color: '#ffffff',
        border: 'none',
        borderRadius: '0.5rem',
        cursor: cohort?.query ? 'pointer' : 'not-allowed',
        fontSize: '1rem',
        transition: 'background-color 0.3s, transform 0.2s',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        marginBottom: '10px'
    }

    const onBlockClick = useCallback(
        (id: number, _qualifierIndex?: number) => {
            const foundBlock = findBlock(id)
            if (foundBlock) {
                props.setSelectedBlock && props.setSelectedBlock(foundBlock)
            }
        },
        [findBlock, props]
    )

    useEffect(() => {
        if (props.selectedBlock?.id) {
            onBlockClick(props.selectedBlock.id)
        }
    }, [onBlockClick, props])

    return (
        <div style={{ padding: '1rem', height: '100%', overflow: 'scroll' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ minWidth: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ marginRight: '10px' }}>Count:</div>
                    {isRefreshing ? <CircularProgress color='inherit' size='1em' /> : <div>{cohort?.cohortSize || '-'}</div>}
                </div>
                {permissions.includes(PlatformPermissions.ACCESS_ADVANCED_ASSISTANT) && (
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <button
                            onClick={toggleQueryView}
                            style={buttonStyles}
                            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = cohort?.query ? '#2b6cb0' : 'gray')}
                            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = cohort?.query ? '#3182ce' : 'gray')}
                            onMouseDown={(e) => (e.currentTarget.style.transform = 'scale(0.95)')}
                            onMouseUp={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                            disabled={!cohort?.query}
                        >
                            {showQueryComponent ? 'Show Raw JSON' : 'Show QueryBlock'}
                        </button>
                    </div>
                )}
            </div>
            {showQueryComponent
                ? cohort?.query && <Query {...props} query={cohort.query} setEditBlock={setEditBlock} onBlockClick={onBlockClick} isBase />
                : cohort?.query && <ReactJson src={JSON.parse(JSON.stringify(cohort.query))} style={{ marginBottom: '25px' }} />}
            {editBlock && <EditBlockDialog editBlock={editBlock} onClose={() => setEditBlock(null)} />}
        </div>
    )
}
