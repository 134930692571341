import { connectRedux, FrameworkComponentProps } from '@om1/platform-utils'
import {
    cohortBlocksEditActions,
    CohortState,
    CriterionNode,
    FilterValueQualifier,
    FilterWithUnitQualifier,
    QualifierOperator,
    QualifierType
} from '../../../state'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { t, Trans } from '@lingui/macro'
import { findQualifierByType, getFilterValuesByField } from '../utils/finders'
import { ReactNode, useMemo } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { isUndefined } from 'lodash'
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Dialog,
    DialogTitle,
    IconButton,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField
} from '@mui/material'
import { findNode } from '../../../state/edit/blocks/cohort-blocks-edit-reducer'
import CloseIcon from '@mui/icons-material/Close'
import { useRefTable } from '@om1/falcon-api/codegen/hooks/useRef'

interface LabNumericQualifierEditFormValues {
    operator: QualifierOperator
    primaryInput: string | number
    secondaryInput: string | number | null
    unit?: string
}

interface LabTextQualifierEditFormValues {
    operator: QualifierOperator
    values: string[]
}

export type LabNumericQualifierEditDialogComponentProps = FrameworkComponentProps<
    {
        criteria: CriterionNode
        type: 'numeric' | 'text'
    },
    typeof cohortBlocksEditActions
>

export const LabNumericTextQualifierEditDialogComponent = ({ state, actions }: LabNumericQualifierEditDialogComponentProps) => {
    const { criteria, type } = state

    if (type === 'numeric') {
        return <LabNumericQualifierEditDialogComponent criteria={criteria} actions={actions} />
    }

    return <LabTextQualifierEditDialogComponent criteria={criteria} actions={actions} />
}

function formValuesToFilterValueQualifier(formValues: LabNumericQualifierEditFormValues): FilterValueQualifier | FilterWithUnitQualifier {
    const hasUnits = !isUndefined(formValues.unit) && formValues.unit !== ''
    const hasSecondaryInput = formValues.operator === QualifierOperator.Between || formValues.operator === QualifierOperator.NotBetween

    if (hasUnits && hasSecondaryInput) {
        return {
            type: QualifierType.FilterWithUnitQualifierDTO,
            field: 'numeric_result',
            values: [formValues.primaryInput.toString(), formValues.secondaryInput?.toString() ?? ''],
            // @ts-ignore unit will always be defined here
            unit: formValues.unit,
            operator: formValues.operator
        }
    } else if (hasUnits) {
        return {
            type: QualifierType.FilterWithUnitQualifierDTO,
            field: 'numeric_result',
            values: [formValues.primaryInput.toString()],
            // @ts-ignore unit will always be defined here
            unit: formValues.unit,
            operator: formValues.operator as QualifierOperator
        }
    } else if (hasSecondaryInput) {
        return {
            type: QualifierType.FilterQualifierDTO,
            field: `numeric_result`,
            values: [formValues.primaryInput.toString(), formValues.secondaryInput?.toString() ?? ''],
            operator: formValues.operator
        }
    }

    return {
        type: QualifierType.FilterQualifierDTO,
        field: `numeric_result`,
        values: [formValues.primaryInput.toString()],
        operator: formValues.operator
    }
}

function LabNumericQualifierEditDialogComponent({ criteria, actions }: { criteria: CriterionNode; actions: typeof cohortBlocksEditActions }) {
    const anchorLabConceptId = getFilterValuesByField(criteria, 'anchor_lab_concept_id')

    const { data: numericUnitsData } = useRefTable('ref_lab_unit', anchorLabConceptId)

    const units = useMemo(() => {
        return numericUnitsData?.pages.flatMap((page) => page.data) ?? []
    }, [numericUnitsData])

    const validationSchema = Yup.object().shape({
        operator: Yup.string().required(),
        primaryInput: Yup.number().required(),
        secondaryInput: Yup.number().when('operator', {
            is: (operator: string) => operator === QualifierOperator.Between || operator === QualifierOperator.NotBetween,
            then: (schema) => {
                return schema.typeError(t`Must be a number`).required()
            },
            otherwise: () => Yup.number().nullable()
        }),
        unit: Yup.string().nullable()
    })

    const initialValue = useMemo(() => {
        const existingWithUnitsQualifier = findQualifierByType<FilterWithUnitQualifier>(criteria.qualifiers, QualifierType.FilterWithUnitQualifierDTO)
        const existingQualifier = findQualifierByType<FilterValueQualifier>(criteria.qualifiers, QualifierType.FilterQualifierDTO)

        if (existingWithUnitsQualifier) {
            return {
                primaryInput: existingWithUnitsQualifier.values[0],
                unit: existingWithUnitsQualifier.unit,
                operator: existingWithUnitsQualifier.operator,
                secondaryInput: existingWithUnitsQualifier.values.length > 1 ? existingWithUnitsQualifier.values[1] : null
            }
        }

        if (existingQualifier) {
            return {
                primaryInput: existingQualifier.values[0],
                operator: existingQualifier.operator,
                secondaryInput: existingQualifier.values.length > 1 ? existingQualifier.values[1] : null
            }
        }

        return {
            primaryInput: 0,
            secondaryInput: null,
            unit: undefined,
            operator: QualifierOperator.GreaterThan
        }
    }, [criteria])

    const {
        control,
        handleSubmit,
        register,
        watch,
        formState: { errors }
    } = useForm<LabNumericQualifierEditFormValues>({
        defaultValues: {
            primaryInput: initialValue.primaryInput,
            secondaryInput: initialValue.secondaryInput,
            operator: initialValue.operator,
            unit: initialValue.unit
        },
        resolver: yupResolver(validationSchema),
        mode: 'onBlur'
    })

    const onCancel = () => {
        actions.labNumericTextQualifierEditDialogTrigger({})
    }

    const onSubmit: SubmitHandler<LabNumericQualifierEditFormValues> = (formValues: LabNumericQualifierEditFormValues) => {
        let qualifiers = [...criteria.qualifiers]
        const newQualifier: FilterValueQualifier | FilterWithUnitQualifier = formValuesToFilterValueQualifier(formValues)
        qualifiers = qualifiers.filter((q) => q.type !== QualifierType.FilterQualifierDTO && q.type !== QualifierType.FilterWithUnitQualifierDTO)
        // @ts-ignore
        qualifiers.push(newQualifier)

        actions.labNumericTextQualifierEditDialogTrigger({})
        actions.criteriaUpdate({ target: { nodeId: criteria.id }, qualifiers })
    }

    const renderSingleInput = (fieldName: 'primaryInput' | 'secondaryInput') => (
        <>
            <Controller
                control={control}
                name={fieldName}
                render={({ field: { onChange, value } }) => (
                    <TextField
                        type='number'
                        {...register(fieldName)}
                        sx={{ marginRight: 1, flex: 1 }}
                        error={!!errors[fieldName]}
                        helperText={errors[fieldName]?.message}
                        data-testid={`input-lab-numeric-value-${fieldName === 'primaryInput' ? 'primary' : 'secondary'}`}
                        onChange={onChange}
                        value={value}
                    />
                )}
            />
        </>
    )

    const renderValueRange = () => (
        <>
            {renderSingleInput('primaryInput')}
            <Box flexShrink={0} marginRight={1}>
                <Trans>and</Trans>
            </Box>
            {renderSingleInput('secondaryInput')}
        </>
    )

    const operatorWatcher = watch('operator')

    const renderFormFields = () => {
        if (operatorWatcher === QualifierOperator.Between || operatorWatcher === QualifierOperator.NotBetween) {
            return renderValueRange()
        }

        return renderSingleInput('primaryInput')
    }

    return (
        <Dialog
            open
            fullWidth
            maxWidth={operatorWatcher === QualifierOperator.Between || operatorWatcher === QualifierOperator.NotBetween ? 'md' : 'sm'}
            onClose={onCancel}
        >
            <DialogTitle>
                <IconButton
                    aria-label='close'
                    onClick={onCancel}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <Box
                        paddingTop={2}
                        display='grid'
                        gridTemplateColumns={
                            operatorWatcher === QualifierOperator.Between || operatorWatcher === QualifierOperator.NotBetween
                                ? // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                                  `auto 1fr 1fr auto 1fr auto`
                                : // eslint-disable-next-line string-to-lingui/missing-lingui-transformation
                                  `auto 1fr 1fr auto`
                        }
                        gap={1}
                        alignItems='center'
                    >
                        <Box flexShrink={0} marginRight={1}>
                            <Trans>Lab Result</Trans>
                        </Box>
                        <Controller
                            control={control}
                            {...register('operator')}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <Select
                                        id='operator'
                                        labelId='operator-label'
                                        sx={{ flex: 1, marginRight: 1 }}
                                        required
                                        fullWidth
                                        value={value}
                                        onChange={onChange as (event: SelectChangeEvent<string>, child: ReactNode) => void}
                                    >
                                        <MenuItem value={QualifierOperator.Equals}>
                                            <Trans>Equals</Trans>
                                        </MenuItem>
                                        <MenuItem value={QualifierOperator.NotEquals}>
                                            <Trans>Not Equal to</Trans>
                                        </MenuItem>
                                        <MenuItem value={QualifierOperator.GreaterThan}>
                                            <Trans>More Than</Trans>
                                        </MenuItem>
                                        <MenuItem value={QualifierOperator.GreaterThanOrEquals}>
                                            <Trans>At Least</Trans>
                                        </MenuItem>
                                        <MenuItem value={QualifierOperator.LessThan}>
                                            <Trans>Less Than</Trans>
                                        </MenuItem>
                                        <MenuItem value={QualifierOperator.LessThanOrEquals}>
                                            <Trans>At Most</Trans>
                                        </MenuItem>
                                        <MenuItem value={QualifierOperator.Between}>
                                            <Trans>Between</Trans>
                                        </MenuItem>
                                        <MenuItem value={QualifierOperator.NotBetween}>
                                            <Trans>Not Between</Trans>
                                        </MenuItem>
                                    </Select>
                                )
                            }}
                        />
                        {renderFormFields()}
                        <Controller
                            control={control}
                            name='unit'
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    id='unit'
                                    labelId='unit-label'
                                    sx={{ flex: 1, marginRight: 1, width: 200 }}
                                    value={value || ''}
                                    onChange={onChange as (event: SelectChangeEvent<string>, child: ReactNode) => void}
                                    IconComponent={
                                        value && value !== ''
                                            ? () => <CloseIcon sx={{ cursor: 'pointer', marginRight: 1 }} onClick={() => onChange('')} />
                                            : undefined
                                    }
                                >
                                    {units.map((unit) => (
                                        <MenuItem key={unit.unit} value={unit.unit}>
                                            {unit.unit}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='text' color='primary' onClick={onCancel}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button variant='contained' type='submit'>
                        <Trans>Save</Trans>
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

function LabTextQualifierEditDialogComponent({ criteria, actions }: { criteria: CriterionNode; actions: typeof cohortBlocksEditActions }) {
    const anchorLabConceptId = getFilterValuesByField(criteria, 'anchor_lab_concept_id')

    const { data: textValuesData, isLoading: textValuesLoading } = useRefTable('ref_lab_text_value', anchorLabConceptId)

    const textValues = useMemo(() => {
        return textValuesData?.pages.flatMap((page) => page.data) ?? []
    }, [textValuesData])

    const validationSchema = Yup.object().shape({
        operator: Yup.string().required(),
        values: Yup.array()
            .of(Yup.string())
            .min(1, t`Please select at least one value`)
            .required()
    })

    const initialValue = useMemo(() => {
        const existingQualifier = findQualifierByType<FilterValueQualifier>(criteria.qualifiers, QualifierType.FilterQualifierDTO)

        if (existingQualifier) {
            return {
                values: existingQualifier.values.map((value) => value.toString()),
                operator: existingQualifier.operator
            }
        }

        return {
            values: [],
            operator: QualifierOperator.In
        }
    }, [criteria])

    const {
        control,
        handleSubmit,
        register,
        formState: { errors }
    } = useForm<LabTextQualifierEditFormValues>({
        defaultValues: {
            values: initialValue.values,
            operator: initialValue.operator
        },
        resolver: yupResolver(validationSchema),
        mode: 'onBlur'
    })

    const onCancel = () => {
        actions.labNumericTextQualifierEditDialogTrigger({})
    }

    const onSubmit: SubmitHandler<LabTextQualifierEditFormValues> = (formValues: LabTextQualifierEditFormValues) => {
        let qualifiers = [...criteria.qualifiers]
        const newQualifier: FilterValueQualifier = {
            type: QualifierType.FilterQualifierDTO,
            field: 'text_result',
            values: formValues.values,
            operator: formValues.operator
        }
        qualifiers = qualifiers.filter((q) => q.type !== QualifierType.FilterQualifierDTO)
        qualifiers.push(newQualifier)
        actions.labNumericTextQualifierEditDialogTrigger({})
        actions.criteriaUpdate({ target: { nodeId: criteria.id }, qualifiers })
    }

    if (textValuesLoading) {
        return (
            <Dialog open fullWidth maxWidth='sm'>
                <DialogTitle>
                    <Trans>Loading...</Trans>
                </DialogTitle>
            </Dialog>
        )
    }

    return (
        <Dialog open fullWidth maxWidth='sm'>
            <DialogTitle>
                <IconButton
                    aria-label='close'
                    onClick={onCancel}
                    sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <Box paddingTop={2} display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                        <Box flexShrink={0} marginRight={1}>
                            <Trans>Observation Value</Trans>
                        </Box>
                        <Controller
                            control={control}
                            name='operator'
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    id='operator'
                                    labelId='operator-label'
                                    sx={{ flex: 1, marginRight: 1 }}
                                    required
                                    value={value}
                                    onChange={onChange as (event: SelectChangeEvent<string>, child: ReactNode) => void}
                                >
                                    <MenuItem value={QualifierOperator.In}>
                                        <Trans>In</Trans>
                                    </MenuItem>
                                    <MenuItem value={QualifierOperator.NotIn}>
                                        <Trans>Not In</Trans>
                                    </MenuItem>
                                </Select>
                            )}
                        />
                        <Controller
                            control={control}
                            name='values'
                            render={({ field: { onChange, value } }) => (
                                <Select
                                    multiple
                                    {...register('values')}
                                    sx={{ marginRight: 1, flex: 1 }}
                                    value={value}
                                    onChange={onChange}
                                    error={!!errors.values}
                                >
                                    {textValues.map((textValue) => (
                                        <MenuItem key={textValue.text_value} value={textValue.text_value}>
                                            {textValue.text_value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='text' color='primary' onClick={onCancel}>
                        <Trans>Cancel</Trans>
                    </Button>
                    <Button variant='contained' type='submit'>
                        <Trans>Save</Trans>
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export function createLabNumericQualifierEditDialogComponent<TState extends { cohort: CohortState }>() {
    return connectRedux(
        LabNumericTextQualifierEditDialogComponent,
        (state: TState) => ({
            criteria: findNode(
                state.cohort.edit.blocks.tree,
                state.cohort.edit.blocks.ui.labNumericQualifierEditDialog.target.nodeId
            ) as CriterionNode,
            type: state.cohort.edit.blocks.ui.labNumericQualifierEditDialog.type
        }),
        cohortBlocksEditActions
    )
}
