/* eslint-disable string-to-lingui/missing-lingui-transformation */
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { IconButton } from '@mui/material'
import { FilterQualifierDTO_Output } from '@om1/falcon-api/codegen/client/models/FilterQualifierDTO_Output'
import { FC } from 'react'
import { QualifierContent } from '../nodes/QualifierContent'
import { QualifierProps } from './Qualifier'

export const tableMap: Record<string, string> = {
    patient_diagnosis: 'diagnosis',
    patient_lab: 'lab',
    patient_medication: 'medication',
    patient_note: 'note',
    patient_external_cohort: 'external cohort',
    observation_period: 'observation period',
    patient_observation: 'observation',
    patient_procedure: 'procedure'
}

const operatorToString = (operator: string): string => {
    const operatorMap: Record<string, string> = {
        in: 'is in',
        notIn: 'is not in',
        equals: 'is',
        notEquals: 'is not',
        gt: 'is greater than',
        gte: 'is greater than or equal to',
        lt: 'is less than',
        lte: 'is less than or equal to',
        between: 'is between',
        notBetween: 'is not between'
    }
    return operatorMap[operator] || operator
}

export const FilterQualifier: FC<
    Omit<QualifierProps, 'qualifier'> & {
        filterQualifierDTO: FilterQualifierDTO_Output
    }
> = ({
    filterQualifierDTO,
    parent,
    onBlockClick,
    disableClick = false,
    qualifierIndex = -1,
    setEditBlock,
    selectedBlock,
    editDisabled,
    setSelectedBlock
}) => {
    const { operator, values } = filterQualifierDTO
    const valueString =
        operator === 'between' || operator === 'notBetween'
            ? `Observation score ${operatorToString(operator)} ${values.join(' and ')}`
            : `Observation score ${operatorToString(operator)} ${values.join(', ')}`

    const handleBlockClick = () => {
        if (onBlockClick && parent?.id) {
            onBlockClick(parent.id, qualifierIndex)
        }
    }

    return (
        <QualifierContent
            parent={parent}
            onBlockClick={handleBlockClick}
            disableClick={disableClick}
            qualifierIndex={qualifierIndex}
            qualifier={filterQualifierDTO}
            setEditBlock={setEditBlock}
            selectedBlock={selectedBlock}
            setSelectedBlock={setSelectedBlock}
        >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ fontSize: '.875rem', lineHeight: '1rem' }}>{valueString}</div>
                    {!editDisabled && (
                        <div style={{ display: 'flex' }}>
                            <IconButton key='delete' color='primary' aria-label='Delete' sx={{ padding: 0.25 }} onClick={() => {}}>
                                <DeleteOutlineIcon fontSize='small' />
                            </IconButton>
                        </div>
                    )}
                </div>
            </div>
        </QualifierContent>
    )
}
