/* eslint-disable string-to-lingui/missing-lingui-transformation */
import AddCommentIcon from '@mui/icons-material/AddComment'
import CommentIcon from '@mui/icons-material/Comment'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import SaveAsIcon from '@mui/icons-material/SaveAs'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import { AssistantConversationCreateDTO } from '@om1/falcon-api/codegen/client/models/AssistantConversationCreateDTO'
import { CohortCreateDTO } from '@om1/falcon-api/codegen/client/models/CohortCreateDTO'
import { QueryDTO_Input } from '@om1/falcon-api/codegen/client/models/QueryDTO_Input'
import { useConversations, useCreateConversation, useDeleteConversation } from '@om1/falcon-api/codegen/hooks/useAssistantConversations'
import { useCohort } from '@om1/falcon-api/codegen/hooks/useCohort'
import { useCreateCohort } from '@om1/falcon-api/codegen/hooks/useExplorerCohorts'
import { useUserPermissions } from '@om1/platform-authentication/auth0/hooks/useUserPermissions'
import { PlatformPermissions } from '@om1/platform-utils/enums'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { StyledMenu } from './StyledMenu'

export const OptionsMenu = (props: { conversationId: string; assistantCohortId: string; referenceCohortId: string | null | undefined }) => {
    const history = useHistory()
    const navigate = history.push
    const { permissions } = useUserPermissions()
    const [openSaveAsDialog, setOpenSaveAsDialog] = useState(false)
    const [newCohortName, setNewCohortName] = useState('')
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const handleOptionsClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleCloseOptionsMenu = () => {
        setAnchorEl(null)
    }
    const { assistantConversations } = useConversations({
        // referenceCohortId: props.referenceCohortId,
        page: 1,
        limit: 50
    })
    const createConversation = useCreateConversation()
    const deleteConversation = useDeleteConversation()
    const { cohort } = useCohort({ cohortId: props.assistantCohortId })
    const createCohort = useCreateCohort()

    const handleDelete = async (id: string, e: React.MouseEvent) => {
        e.stopPropagation()
        if (window.confirm('Are you sure you want to delete this conversation?')) {
            await deleteConversation.mutateAsync({ id }).then(() => {
                props.referenceCohortId ? navigate(`/cohort-builder/custom/${props.referenceCohortId}/edit`) : navigate(`/cohort-builder/dashboard/`)
            })
        }
    }

    const handleSaveAsDialogSave = () => {
        const newCohortData: CohortCreateDTO = {
            name: newCohortName,
            query: cohort?.query as QueryDTO_Input,
            analyticsDatasetId: String(cohort?.analyticsDataset.id),
            isPrivate: false
        }

        createCohort.mutate(newCohortData, {
            onSuccess: (data) => {
                navigate(`/cohort-builder/custom/${data.id}/edit`)
            },
            onError: (error) => {
                console.error('Error creating cohort:', error)
            }
        })
    }

    const handleCreateConversation = () => {
        const newConversation: AssistantConversationCreateDTO = {
            referenceCohortId: props.referenceCohortId
        }

        createConversation.mutate(
            { requestBody: newConversation },
            {
                onSuccess: (createdConversation) => {
                    onConversationSelect?.(createdConversation.id)
                }
            }
        )
    }

    function onConversationSelect(id: string) {
        navigate(`/cohort-builder/assistant/conversation/${id}`)
    }

    const open = Boolean(anchorEl)

    return (
        <div>
            <Button id='button' aria-haspopup='true' onClick={handleOptionsClick}>
                Options
            </Button>
            <StyledMenu anchorEl={anchorEl} open={open} onClose={handleCloseOptionsMenu}>
                {permissions.includes(PlatformPermissions.ACCESS_ADVANCED_ASSISTANT) && (
                    <MenuItem
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            handleCloseOptionsMenu()
                            setOpenSaveAsDialog(true)
                        }}
                        disableRipple
                    >
                        <SaveAsIcon />
                        Save As New Custom Cohort
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() => {
                        handleCreateConversation()
                        handleCloseOptionsMenu()
                    }}
                    disableRipple
                    key={`menuItem-new`}
                >
                    <AddCommentIcon />
                    Start New Conversation
                </MenuItem>
                <MenuItem
                    onClick={(e) => {
                        handleDelete(props.conversationId, e)
                    }}
                    disableRipple
                >
                    <DeleteForeverIcon />
                    Delete Conversation
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                {assistantConversations?.pages.map((page, pageIndex) =>
                    page.data.map((conversation, index) => (
                        <MenuItem
                            onClick={() => {
                                navigate(`/cohort-builder/assistant/conversation/${conversation.id}`)
                                handleCloseOptionsMenu()
                            }}
                            disableRipple
                            key={`menuItem-${index}`}
                        >
                            <CommentIcon />
                            {conversation.title || conversation.id}
                        </MenuItem>
                    ))
                )}
            </StyledMenu>
            <Dialog
                open={openSaveAsDialog}
                onClose={() => {
                    setOpenSaveAsDialog(false)
                }}
            >
                <DialogTitle>Save Cohort</DialogTitle>
                <DialogContent style={{ width: '400px', height: '100px' }}>
                    <TextField
                        autoFocus
                        margin='dense'
                        label='Cohort Name'
                        type='text'
                        fullWidth
                        value={newCohortName}
                        onChange={(e) => setNewCohortName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpenSaveAsDialog(false)
                        }}
                        color='primary'
                    >
                        Cancel
                    </Button>
                    <Button onClick={handleSaveAsDialogSave} color='primary' disabled={!newCohortName.trim()}>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
