import { ApiError, DataDeliveryAdminTasksService, DeliveryDTO, TaskDTO } from '@om1/falcon-api'
import { handleApiError } from '@om1/falcon-api/utils'
import { notificationActions } from '@om1/platform-notifications'
import { call, put, select } from 'redux-saga/effects'
import { BackOfficeState } from '../state'
import { dataDeliveryActions } from '../state/data-delivery'

export function createExecuteDataDeliverySaga() {
    return function* (action: ReturnType<typeof dataDeliveryActions.dataDeliveryExecute>) {
        const { deliveryId } = action.payload
        let dataDelivery: DeliveryDTO | undefined = yield select((store: { backOffice: BackOfficeState }) => {
            return store.backOffice.dataDelivery.list.data.find((x) => x.id === deliveryId)
        })
        if (dataDelivery) {
            dataDelivery = { ...dataDelivery, task: { taskId: '', status: 'STARTED', result: {} } }
            yield put(dataDeliveryActions.dataDeliveriesListAddEdit({ dataDelivery, isAdd: false }))
        }

        let taskId: string | undefined
        try {
            const task: TaskDTO = yield call(DataDeliveryAdminTasksService.executeDataDeliveryTasksExecuteDeliveryIdGet, {
                deliveryId: deliveryId
            })
            taskId = task.taskId
            if (dataDelivery) {
                dataDelivery = { ...dataDelivery, task: { taskId, status: 'STARTED', result: {} } }
                yield put(dataDeliveryActions.dataDeliveriesListAddEdit({ dataDelivery, isAdd: false }))
            }
        } catch (error: any) {
            const apiError: ApiError = error as ApiError
            switch (apiError.status) {
                case 401:
                    if (dataDelivery) {
                        dataDelivery = { ...dataDelivery, task: null }
                        yield put(dataDeliveryActions.dataDeliveriesListAddEdit({ dataDelivery, isAdd: false }))
                    }
                    // yield put(keycloakActions.sessionExpired())
                    yield put(notificationActions.error('Session Expired'))
                    break
                case 422:
                    if (dataDelivery) {
                        dataDelivery = { ...dataDelivery, task: null }
                        yield put(dataDeliveryActions.dataDeliveriesListAddEdit({ dataDelivery, isAdd: false }))
                    }
                    yield put(notificationActions.error('Unprocessable'))
                    break
                default:
                    if (dataDelivery) {
                        dataDelivery = { ...dataDelivery, task: null }
                        yield put(dataDeliveryActions.dataDeliveriesListAddEdit({ dataDelivery, isAdd: false }))
                    }
                    yield handleApiError(error)
                    break
            }
        }

        if (taskId) {
            yield put(dataDeliveryActions.dataDeliveryAddTaskListener({ taskId, deliveryId }))
        }
    }
}

export type ExecuteDataDeliverySaga = ReturnType<typeof createExecuteDataDeliverySaga>
